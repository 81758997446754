import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import Layout from '../../components/layout'
import Meta from '../../components/meta'
import { Link, graphql } from 'gatsby'
import filterLocale from '../../components/utils/filter-locale'
import SupportSearch from '../../components/support/support-search'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import localeCheck from '../../components/utils/locale-check'

const ContentsSection = tw.section`w-full bg-gray-200 pt-20`
const ContentsContainer = tw.div`w-95 md:w-11/12 bg-white py-10 px-4 md:px-10 mx-auto border-l border-r border-gray-300 border-b rounded mb-16`

const Title = tw.h3`font-display text-4xl md:text-5xl text-gray-700 font-bold text-center mb-3 md:mb-0`

const MainHead1 = tw.h1`font-display mt-4 font-bold text-4xl`
const MainHead2 = tw.h2`font-display mt-4 font-bold text-3xl`
const MainHead4 = tw.h4`font-display mt-4 font-bold text-xl`
const MainHead5 = tw.h5`font-display mt-4 font-bold text-lg`
const MainHead6 = tw.h6`font-display mt-4 font-bold text-base`
const ListItem = tw.li`list-disc font-display`
const UnorderedList = tw.ul`ml-6`
const ContentLink = styled(Link)`
	${tw`underline text-blue-500 hover:no-underline font-display`}
`
const SubHead = tw.h3`font-display mt-4 font-bold text-2xl`
const Texts = tw.p`font-display my-4 leading-relaxed break-words`

const DolphinPurchaseOutside = ({ data: { page } }) => {
	const locale = 'en-NZ'
	const defaultLocale = 'en-AU'
	const [{ node }] = filterLocale(page.edges, locale)

	const { seoTitle, seoKeywords, seoDescription, body } = node

	const options = {
		renderNode: {
			[BLOCKS.HEADING_1]: (node, children) => {
				return <MainHead1>{children}</MainHead1>
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <MainHead2>{children}</MainHead2>
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <SubHead>{children}</SubHead>
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <MainHead4>{children}</MainHead4>
			},
			[BLOCKS.HEADING_5]: (node, children) => {
				return <MainHead5>{children}</MainHead5>
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <MainHead6>{children}</MainHead6>
			},
			[BLOCKS.QUOTE]: (node, children) => {
				const text = node.content[0].content[0].value
				const auth = node.content[1].content[0].value
				return (
					<BlockQuote>
						<Quote>{text}</Quote>
						<QuoteAuthor>{auth}</QuoteAuthor>
					</BlockQuote>
				)
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <Texts>{children}</Texts>
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => {
				const { description, title, file } = node.data.target.fields
				const checkfile = file[locale].contentType.indexOf('video')
				return checkfile < 0 ? (
					<EmbeddedAssetContainer>
						<img src={file[locale].url} alt={description[locale]} />
					</EmbeddedAssetContainer>
				) : (
					<video controls width="100%" height="100%" loop>
						<source src={file[locale].url} type="video/mp4"></source>
					</video>
				)
			},
			[INLINES.ASSET_HYPERLINK]: (node, children) => {
				const text = node.content[0].value
				const { description, file } = node.data.target.fields
				return (
					<>
						<ImageWrapContainer>
							<img src={file[locale].url} alt={description[locale]} />
						</ImageWrapContainer>
						{text.split('\\n').map((item, i) => {
							return <span key={i}>{item}</span>
						})}
					</>
				)
			},
			[INLINES.HYPERLINK]: (node, children) => {
				return (
					<ContentLink to={node?.data?.uri} target="_blank">
						{children}
					</ContentLink>
				)
			},
			[BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
			[BLOCKS.UL_LIST]: (node, children) => (
				<UnorderedList>{children}</UnorderedList>
			),
		},
	}

	const checkedLocale = localeCheck(locale, defaultLocale)

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
			/>
			<SupportSearch />

			<ContentsSection>
				<ContentsContainer>
					<Title>{seoTitle}</Title>
					{documentToReactComponents(body.json, options)}
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}

export const SUPPORT_DEALER_QUERY = graphql`
	query DolphinPurchaseOutsideQueryNz {
		page: allContentfulPage(
			filter: { contentful_id: { eq: "1jIiUrj76aFAnPeArgLa7p" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
				}
			}
		}
	}
`
DolphinPurchaseOutside.propTypes = {
	data: PropTypes.objectOf({
		page: PropTypes.any,
	}),
}

export default DolphinPurchaseOutside
